<template>
  <div id="app">
    <LoadingScreen :isLoading="isLoading" />
    <Mainbox :selected="selected" v-if="selected.id > 0"></Mainbox>
    <div class="key">
      <img :src="require('/public/key.png')" class="img-fluid" />
    </div>
    <Element v-for="el in elements" :key="el.id" :element="el" v-on:focused="setFocused"></Element>
    <div class="btmkey">
      <div class="type1"><span class="v-center">Alkali<br>Metal</span></div>
      <div class="type2"><span class="v-center">Alkaline<br>Earth</span></div>
      <div class="type3"><span class="v-center">Basic<br>Metal</span></div>
      <div class="type4"><span class="v-center">Halogen</span></div>
      <div class="type5"><span class="v-center">Noble Gas</span></div>
      <div class="type6"><span class="v-center">Non Metal</span></div>
      <div class="type7"><span class="v-center">Rare Earth</span></div>
      <div class="type8"><span class="v-center">Semi<br>Metal</span></div>
      <div class="type9"><span class="v-center">Transition<br>Metal</span></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Mainbox from './components/mainbox.vue'
import Element from './components/element.vue'
import LoadingScreen from './components/LoadingScreen.vue'


export default {
  name: 'App',
  data () {
    return {
      elements: Object,
      selected: Object,
      isLoading: true
    }
  },
  components: {
    Mainbox,
    Element,
    LoadingScreen
  },
  methods: {
    setFocused: function(selected){
      this.selected = this.elements.filter(element => element.id == selected)[0]
    }
  },
  mounted() {    
    this.isLoading = true
    import('./data.js').then(data => {
      this.elements = data.default
      this.setFocused(1)
      this.isLoading = false
    });
  }
}
</script>
 
<style>
html {
  height: 100%;
  font-size: 14px;
}
body {
  height: 100%;
  margin: 0rem;
  background-color: #3b3b3b;  
}
sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub { 
  top: 0.4em; 
}
#app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 2rem;
  left: 0;
  box-sizing: border-box;
  padding: 1rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  margin-left:auto;
  margin-right: auto;
  display: grid;
  column-gap: .2rem;
  row-gap: .2rem;
  grid-template-columns: repeat(18, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-template-areas:
    /* "topleft topleft mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox topright topright topright topright topright topright" */
    /* ". . mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox . . . . . ." */
    "e1 . mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox . . . . . e2"
    "e3 e4 mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox e5 e6 e7 e8 e9 e10"
    "e11 e12 mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox mainbox e13 e14 e15 e16 e17 e18"
    "e19 e20 e21 e22 e23 e24 e25 e26 e27 e28 e29 e30 e31 e32 e33 e34 e35 e36"
    "e37 e38 e39 e40 e41 e42 e43 e44 e45 e46 e47 e48 e49 e50 e51 e52 e53 e54"
    "e55 e56 . e72 e73 e74 e75 e76 e77 e78 e79 e80 e81 e82 e83 e84 e85 e86"
    "e87 e88 . e104 e105 e106 e107 e108 e109 e110 e111 e112 e113 e114 e115 e116 e117 e118"    
    /* "key key key . . . . . . . . . . . . . . ." */
    "key key key e57 e58 e59 e60 e61 e62 e63 e64 e65 e66 e67 e68 e69 e70 e71"
    "key key key e89 e90 e91 e92 e93 e94 e95 e96 e97 e98 e99 e100 e101 e102 e103"
    "key key key btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey btmkey";

} 
#bottom-blank{
  grid-area: bottom-blank;
  min-height: 3rem;
}
.element{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto min-content;
  grid-template-areas: 
  "title"
  "number"
  ;
}
.element:hover{
  cursor: pointer;
}
.v-center{
  display: grid;
  align-items: center;
  height: 100%;
}
.btmkey{
  grid-area: btmkey;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: auto;
  column-gap: .4rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
  align-items: stretch;
  height: 100%;
  font-weight:600;
}
.key{
  align-self: center;
  grid-area: key;
}
.type1{
  background-color: #fedb37;
}
.type2{
  background-color: #fe8982;
}
.type3{
  background-color: #ca90ff;
}
.type4{
  background-color: #14d717;
}
.type5{
  background-color: #0eaced;
}
.type6{
  background-color: #ff8b29;
}
.type7{
  background-color: #b0ef49;
}
.type8{
  background-color: #7ffe19;
}
.type9{
  background-color: #79e5ff;
}
.img-fluid{
  max-width: 100%;
  height: auto;
}
.text-center{
  text-align: center;
}
#topleft { grid-area: blank1;}
#topright { grid-area: blank2;}
#history {
  grid-area: history;
  text-align: center;
  color: #fff;
}
#temperature {
  grid-area: temperature;
  text-align: center;
  color: #fff;
}
#bottomright { grid-area: blank3;}
</style>