<template>
  <div id="mainbox">
    <!-- <div id="info">
      <span class="mb-title">{{ selected.name }}</span>
      <table width="100%">
        <tr>
          <td width="50%">Key isotopes</td>
          <td v-html="selected.isotopes"></td>
        </tr>
        <tr>
          <td width="50%">Electron configuration</td>
          <td v-html="selected.electron"></td>
        </tr>
        <tr>
          <td width="50%">Density</td>
          <td v-html="selected.density"></td>
        </tr>
        <tr>
          <td width="50%">First ionisation energy</td>
          <td v-html="selected.ionisation"></td>
        </tr>
      </table>
    </div> -->
    <div id="images">
      <div id="img1" :style="{backgroundImage:`url(${selected.image1})`}" class="mainboxImage">
        <span class="mainboxTitle">{{ selected.name }}</span>
      </div>
      <!-- <div id="elementCard" :class="selected.class">
        <div class="symbol">{{ selected.symbol }}</div>
        <div class="card-title">{{ selected.name }}</div>
        <div class="card-number number">{{ selected.id }}</div>
        <div :class="'atomic-mass ' + selected.class">
          {{ selected.atomicMass }}
        </div>
      </div> -->
      <div id="img2" :style="{backgroundImage:`url(${selected.image2})`}" class="mainboxImage"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mainbox",
  props: {
    selected: {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mainbox {
  grid-area: mainbox;
  background-color: #151f20;
  color: white;
  display: grid;
  padding: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  grid-template-areas: 
    "info"
    "images"
  ;
  margin: 0 1rem 1rem 1rem;
}
.mainboxImage{
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
}
.mainboxTitle{
  color: white;
  font-weight: bold;
  padding: 10px;
  font-size: 2rem;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
}
#info {
  grid-area: info;
}
#images{  
  grid-area: images;
  display: grid;
  column-gap: 1rem;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto min-content;
  grid-template-areas:
    "img1 img2";
}
#img1 {
  grid-area: img1;
}
#img2 {
  grid-area: img2;
}
#elementCard {
  color: black;
  grid-area: elementCard;
  display: grid;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto min-content min-content;
  grid-template-areas:
    "symbol symbol symbol"
    "card-title card-title card-title"
    "card-number atomic-mass atomic-mass";
}
.symbol {
  grid-area: symbol;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  align-self: center;
}
.card-title {
  grid-area: card-title;
  text-align: center;
}
.card-number {
  grid-area: card-number;
}
.atomic-mass {
  grid-area: atomic-mass;
  text-align: center;
  align-self: center;
}
.mb-title {
  font-size: 1.7rem;
}
</style>
