<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
      <div id="loadingContainer">
        <img src="logo.png" class="img-fluid"/>
        <br>Loading
        <div class="loadingio-spinner-reload-iavct2vnni8"><div class="ldio-gv21l18h6p4">
        <div><div></div><div></div><div></div></div>
        </div></div>
      </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["isLoading"]
};
</script>

<style>
#loadingContainer{
    width:250px;
    margin-left: auto;
    margin-right: auto;
}
.loader {
  background-color: #000017;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  z-index: 10;
}


/* Styles for loading icon */
@keyframes ldio-gv21l18h6p4 { 0% { transform: rotate(0) } 100% { transform: rotate(360deg); } }
.ldio-gv21l18h6p4 > div {
  animation: ldio-gv21l18h6p4 1s infinite linear;
  transform-origin: 100px 100px;
}
.ldio-gv21l18h6p4 > div div { position: absolute; }
.ldio-gv21l18h6p4 > div div:nth-child(1), .ldio-gv21l18h6p4 > div div:nth-child(2) {
  width: 70px;
  height: 70px;
  border: 10px solid;
  border-radius: 50%;
  border-color: transparent #8cc63e #8cc63e #8cc63e;
  box-sizing: border-box;
  position: absolute;
  transform: rotate(45deg);
  transform-origin: 100px 100px;
}
.ldio-gv21l18h6p4 > div div:nth-child(1) {
  transform: rotate(45deg) translate(65px, 65px);
}
.ldio-gv21l18h6p4 > div div:nth-child(2) {
  transform: rotate(0deg) translate(65px, 65px);;
}
.ldio-gv21l18h6p4 > div div:nth-child(3) {
  width: 0;
  height: 0;
  border: 10px solid;
  border-color: transparent transparent transparent #8cc63e;
  transform: translate(100px, 60px);
}
.loadingio-spinner-reload-iavct2vnni8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-gv21l18h6p4 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-gv21l18h6p4 div { box-sizing: content-box; }

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>