<template>
  <!-- <div :id="'e' + element.id" :class="'element ' + element.class" @click="focus"> -->
  <div :style="'grid-area:e' + element.id" :class="'element ' + element.class" @click="focus">
    <div class="top-left">{{ element.id }}</div>
    <div class="top-right">{{ element.atomicMass }}</div>
    <div class="main">
      <div>
        <span class="title">{{ element.symbol }}</span><br>      
        <span class="elname">{{ element.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Element', 
  props: { 
      element: Object
  },
  computed: {
    theNumber: function(text) {
        return text.substring(1)
    }
  },
  methods: {
    focus: function(){
      this.$emit("focused", this.element.id)
    }
  }
}
</script>

<style scoped>
.element{
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
  grid-template-areas: 
  "top-left top-right"
  "main main"
  ;

}
.top-left{
  margin: 3px;
  grid-area: top-left;
  text-align: left;
  font-size: .6rem;
  font-weight: bold;
}
.top-right{
  margin: 3px;
  grid-area: top-right;
  text-align: right;
  font-size: .6rem;
  font-weight: bold;
}
.main{
  grid-area: main;
  text-align: center;
  align-self: center;
}
.title{
  font-size: 1.5rem;  
  font-weight: bold;
  padding:5px 10px;
  /* margin-block-start: 0.5em;
  margin-block-end: 0.5em; */
  align-self: center;
}
.elname{
  font-size: .57rem;
  font-weight: bold;
}
</style>